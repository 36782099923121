@import './config/variables.scss';
@import './assets//fonts/icons/style.scss';



@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Light.otf') format('opentype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Avenir';
  src: url('./assets/fonts/AvenirLTStd-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Avenir Black';
  src: url('./assets/fonts/AvenirLTStd-Black.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'learnry';
  src:  url('./assets/fonts/icons/fonts/learnry.eot');
  src:  url('./assets/fonts/icons/fonts/learnry.eot') format('embedded-opentype'),
    url('./assets/fonts/icons/fonts/learnry.ttf') format('truetype'),
    url('./assets/fonts/icons/fonts/learnry.woff') format('woff'),
    url('./assets/fonts/icons/fonts/learnry.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'learnry' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



* {-webkit-font-smoothing: antialiased;}
* {
  font-family: 'Avenir';
}
body {
  background-color: var(--body-bg);
  font-size: 14px;
  font-family: 'Avenir';
}
.alice-carousel__stage-item {
  padding: 0 10px;
}

#htmlBackground {
  background: $grey100;
}

.App {
  width: 100%;
  overflow-x: hidden;
  min-height: 100vh;

  &--body {
    margin-top: 50px;
  }

  a {
    color: $black100;

    &:hover {
      text-decoration: none;
    }
  }
  main {
    min-height: 75vh;
  }
}

.inline-center{
  display: inline-flex;
  align-items: center;
}

.nav-icons{
  font-size: 1rem;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1400px;
    padding-left: 5%;
    padding-right: 5%;
  }
}
.Toastify__toast-container {
  width: auto;
  min-width: 300px;
}
.Toastify__toast--success {
  background: #161531;
}
.Toastify__toast--error::before {
  content: url(assets/svgFiles/check-error.svg);
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.Toastify__toast--success::before {
  content: url(assets/svgFiles/check-toast.svg);
  position: relative;
  top: 3px;
  margin-right: 10px;
}

.Toastify__toast {
  border-radius: 8px;
  font-weight: 500;
  min-height: 50px;
}
.Toastify__toast-body {
  font-size: 14px;
  text-align: center;
}

.Toastify__close-button {
  display: none;
}
.center-loader{
  top: 45vh;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
}
