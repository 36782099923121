$grey100: #e5e5e5;
$grey200: #ebebeb;
$grey300: #bdbdbd;
$grey400: #828282;
$grey500: #4f4f4f;
$grey600: #ebebeb;
$purple100: #f0f1ff;
$purple200: #b3b9f7;
$purple300: #6873ef;
$purple400: #161531;
$pink100: #faefff;
$pink200: #ba26ff;
$pink300: #f1f2ff;
$white: #ffffff;
$whiteBg: #f7f7f7;
$whiteBrd: #f4f2f2;
$boxShadow: rgba(229, 229, 229, 0.2);
$black100: #1a1a1a;
$black200: #161531;
$green100: #7cc893;
$danger: #dd2828;


:root {
    --nav-active: #6873ef;
    --nav-bg: #fff;
    --body-bg: #e7e7e7;
    --stroke-color: #4f4f4f;
  }