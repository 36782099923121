@import '../../config/variables.scss';

.nav__bar_wrap {
  background: var(--nav-bg);
  box-shadow: 0px 1px 20px 0px #7e7e7e56;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
// notification-sd__wrapper
.navigationbar {
  background: var(--nav-bg);
  height: 72px;
  z-index: 100;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0px 5%;
  flex-direction: row;

  &--bottom {
    @media screen and (max-width: 650px) {
      bottom: 0;
    }
  }
  &__mobile-nav-options {
    padding: 20px 30px;
    background-color:var(--nav-bg);
    position: absolute;
    right: 5%;
    bottom: 100%;
    box-shadow: 0px 4px 4px rgba(229, 229, 229, 0.2);
    border-radius: 4px 4px 0px 0px;
    width: 200px;
    margin: auto;

    .nav-links--item {
      flex-direction: row !important;
      padding: 20px 0;
    }
  }

  .logo-item {
    max-width: 100px;

    img {
      height: 40px;
      object-fit: cover;
    }
  }

  .profile-item {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    display: flex;
    align-items: center;

    .avartar {
      background: #e9effb;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #4c8afa;
      font-weight: 800;
      font-size: 16px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .dropdown-stack {
      position: relative;
      width: fit-content;

      .dropdown {
        width: fit-content;
        padding: 0 5px;
        cursor: pointer;
        height: 20px;
        position: relative;
        display: flex;
        align-items: center;

        span {
          background: #e9effb;
          padding: 4px 10px;
          border-radius: 5px;
          color: #4c8afa;
          font-size: 12px;
        }

        &::after {
          position: absolute;
          content: '';
          right: -10px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #626263;
        }
      }

      .dropdown__content {
        position: absolute;
        top: calc(100% + 15px);
        padding: 10px 20px;
        right: -20%;
        background: #fff;
        border-radius: 0px 0px 0px 9px;
        width: 306px;
        opacity: 0;
        z-index: 200;
        height: fit-content;
        transition: height 200ms ease-in-out;
        opacity: 1;
        box-shadow: 0px 2px 5px 1px rgba(173, 173, 173, 0.212);

        &--item {
          padding: 15px 0;
          cursor: pointer;
          width: 100%;
          display: inline-block;

          &:hover {
            background-color: #e9effb77;
          }

          span {
            background: #e9effb;
            padding: 4px 10px;
            border-radius: 5px;
            color: #4c8afa;
            font-size: 12px;
          }

          .profile {
            all: unset;
          }
        }

        .border-bottom {
          border-bottom: 1px solid rgba(192, 192, 192, 0.76);
        }
      }
    }
  }

  .nav-links {
    display: flex;
    flex: 4 1;
    justify-content: space-between;
    margin: 0 8%;

    @media screen and (max-width: 650px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--nav-bg);
      height: 72px;
      z-index: 100;
      display: flex;
      align-items: center;
      padding: 0px 5%;
      margin: 0;
      box-shadow: 0px -2px 20px 0px #7e7e7e56;

      &--item {
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &--item {
      cursor: pointer;
      color: #4f4f4f;

      .nav-icon,
      .nav-icon-2 {
        margin-right: 5px;
      }

      .nav-icon-2 {
        display: none;
      }

      &:active,
      &-active {
        .nav-icon-2 {
          display: unset;
        }
        .nav-icon {
          display: none;
        }
      }
    }
  }
}

.navigationbar + * {
  margin-top: 72px;
}

@media screen and (max-width: 1200px) {
  .navigationbar {
    padding: 0px 1%;
  }
}

@media screen and (max-width: 997px) {
  .navigationbar .nav-links {
    flex: 4 1;
    margin: 10px 2% 0;
  }
  .nav-links--item {
    font-size: 12px;
  }
  .nav-links--item svg {
    height: 20px;
  }
  .navigationbar {
    max-width: 85%;
  }
  .navigationbar .logo-item img {
    height: 40px;
    position: relative;
    top: -5px;
  }
}

@media screen and (max-width: 767px) {
  .navigationbar .profile-item {
    position: relative;
    left: -10px;
  }
  .navigationbar {
    max-width: 85%;
  }
  .navigationbar .logo-item img {
    height: 30px;
    position: relative;
    left: -10px;
    top: 5px;
  }
  .navigationbar .profile-item .avartar {
    width: 30px;
    height: 30px;
  }
  .navigationbar .profile-item .dropdown-stack .dropdown span {
    font-size: 10px;
  }
}

//notification icons:
.notification-sd {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  .top-bell {
    position: absolute;
    right: 0;
    font-size: 8px;
    top: 5px;
  }
  h1 {
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    color: #1a1a1a;
  }
  &__wrapper {
    position: absolute;
    top: calc(100% + 15px);
    padding: 10px 20px;
    right: -20%;
    background: #fff;
    border-radius: 0px 0px 0px 9px;
    width: 342px;
    opacity: 0;
    z-index: 200;
    height: -moz-fit-content;
    height: fit-content;
    transition: height 200ms ease-in-out;
    opacity: 1;
    box-shadow: 0px 2px 5px 1px rgba(173, 173, 173, 0.212);
    font-weight: 300;
    overflow-y: scroll;
    max-height: 80vh;
  }
  &__new-notification {
    display: flex;
    align-items: center;
    background: #f8f8f8;
    padding: 8px 5px;
    margin-bottom: 23px;
  }
  &__old-notification {
    display: flex;
    align-items: center;
    padding: 0 26px;
    margin-bottom: 23px;
    .fa-circle {
      display: none;
    }
  }
  &__badge {
    padding: 3px 8px;
    background: #6873ef;
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
  }
  .fa-circle {
    color: #6873ef;
    margin-top: -5px;
    margin-right: 0.75rem;
    font-size: 10px;
  }
  &__text {
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
    }
    span {
      margin-top: 6px;
      font-size: 11px;
      line-height: 15px;
      color: #828282;
      font-weight: normal;
    }
  }

  @media screen and (max-width: 767px) {
    &__wrapper {
      right: -424%;
      width: 300px;
    }
  }
}


