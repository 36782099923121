@import '../../../config//variables.scss';

@font-face {
  font-family: 'learnry';
  src:  url('fonts/learnry.eot?6tz1yq');
  src:  url('fonts/learnry.eot?6tz1yq#iefix') format('embedded-opentype'),
    url('fonts/learnry.ttf?6tz1yq') format('truetype'),
    url('fonts/learnry.woff?6tz1yq') format('woff'),
    url('fonts/learnry.svg?6tz1yq#learnry') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'learnry' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-more:before {
  content: "\e907";
  color: var(--stroke-color);
}
.icon-settings--stroke:before {
  content: "\e901";
  color: var(--stroke-color);
}
.icon-leaderboard-stroke:before {
  content: "\e900";
  color: var(--stroke-color);
}
.icon-shopping-bag-filled-1:before {
  content: "\e902";
  color: var(--nav-active)
}
.icon-Shopping-Bag_3165314:before {
  content: "\e903";
  color: var(--stroke-color);
}
.icon-files-icon:before {
  content: "\e904";
  color: var(--stroke-color);
}
.icon-user-stroke:before {
  content: "\e912";
  color: var(--stroke-color);
}
.icon-forum:before {
  content: "\e914";
  color: var(--stroke-color);
}
.icon-leaderboard_filled:before {
  content: "\e90f";
  color: var(--nav-active)
}
.icon-more-filled:before {
  content: "\e910";
  color: var(--nav-active)
}
.icon-user-filled:before {
  content: "\e911";
  color: var(--nav-active)
}
.icon-notes:before {
  content: "\e905";
  color: var(--stroke-color);
}
.icon-courses:before {
  content: "\e906";
  color: var(--stroke-color);
}
.icon-home:before {
  content: "\e908";
  color: var(--stroke-color);
}
.icon-courses-book-filled:before {
  content: "\e90d";
  color: var(--nav-active)
}
.icon-files-filled:before {
  content: "\e909";
  color: var(--nav-active)
}
.icon-forum-filled:before {
  content: "\e90a";
  color: var(--nav-active)
}
.icon-home-filled:before {
  content: "\e90b";
  color: var(--nav-active)
}
.icon-settings-filled:before {
  content: "\e90c";
  color: var(--nav-active)
}
.icon-down-arrow:before {
  content: "\e90e";
  color: var(--stroke-color);
}
.icon-notification:before {
  content: "\e913";
  color: var(--stroke-color);
}
