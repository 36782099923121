@import '../../config/variables.scss';
.footer {
  margin-top: 60px;
  padding: 20px 0;
  background-color: var(--body-bg);
  border-top: 1px solid $grey300;
  font-size: 12px;
  color: $grey500;

  & a {
    color: $grey500;
  }
}

#course-and-details ~ .footer {
  margin-right: 5%;
  margin-left: auto;
  max-width: 60%;
}
