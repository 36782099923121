@import '../../config/variables.scss';

.nav-links--item{
  color: var(--stroke-color)!important;
}

.nav-links--item-active .nav-icons{
  font-size: 1rem;
}
.nav-links--item .nav-icons{
  font-size: 1rem;
}

.navigationbar.course-detail-nav {
  .profile-item {
    display: inline-block;
    flex: 0;
    justify-content: flex-end;
    display: flex;
    align-items: center;
  }

  .profile-item.top-profile-item {
    flex: 2 1;
  }

  .hamburger{
      cursor:pointer;
      position: relative;
      z-index: 5;
  }

  .name_course_title {
    display: flex;
    justify-content: center;
    flex: 1 1;
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    /* header */
    color: #161531;
  }

  .nav-links {
    display: flex;
    flex: 4 1;
    justify-content: space-between;
    margin: 0 8%;

    @media screen and (max-width: 998px) {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #ffffff;
      height: 72px;
      z-index: 100;
      display: flex;
      align-items: center;
      padding: 0px 5%;
      margin: 0;
      box-shadow: 0px -2px 20px 0px #7e7e7e56;

      &--item {
        margin-right: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

     
    }

    &--item {
      cursor: pointer;
      color: #4f4f4f;

      .nav-icon,
      .nav-icon-2 {
        margin-right: 5px;
      }

      .nav-icon-2 {
        display: none;
      }

      
      // .nav-icons{
      //   border: $icon-normal;
      // }

      &:active,
      &-active {
        .nav-icon-2 {
          display: unset;
        }
        .nav-icon {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 997px) {
   
    .navigationbar.course-detail-nav {
      max-width: 92%;
    }
}

@media screen and (max-width: 358px) {
  .navigationbar.course-detail-nav .name_course_title {
    display: inline-block;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 15px;
  }
}
